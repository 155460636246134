import { FC } from 'react';

import { Box, Divider, Link, Typography } from '@mui/material';
import { useGetManagerContactsQuery } from '@otello/api';
import { Bell, Telegram } from '@otello/assets';
import { DigestBadge } from '@otello/features';
import {
  createPreloadableComponent,
  feedback_url,
  getFormattedPhone,
  otello_mail,
  pages,
  rem,
} from '@otello/helpers';
import { useAppSelector } from '@otello/store';
import { themePalette } from '@otello/theme';
import { ButtonBase, DataLoader, SelectMenu } from '@otello/ui';
import { useNavigate } from 'react-router-dom';

import { RootWrapper } from '../index.styles';

import { MenuUser } from './features/MenuUser/MenuUser';
import { NavigationDesktop } from './features/Navigation/Navigation.desktop';
import { HeaderBaseProps } from './Header';
import { ContactsBoxStyled, WrapperHeaderInfo } from './Header.styles';

const DigestPage = createPreloadableComponent(() =>
  import('@otello/pages/digest').then((module) => ({
    default: module.default.Digest,
  })),
);

export const HeaderDesktop: FC<HeaderBaseProps> = ({
  hotelsList,
  handleHotelChange,
  isNeedBackendSearch,
  handleSearchHotel,
  isLoading,
  isListLoading,
}) => {
  const active = useAppSelector((state) => state.hotel.active);

  const navigate = useNavigate();

  const {
    data: managerContacts,
    isLoading: managerContactsIsLoading,
    isUninitialized: isManagerContactsUninitialized,
  } = useGetManagerContactsQuery(
    {
      hotelId: String(active?.id),
    },
    {
      skip: !active?.id,
    },
  );

  const handlePreloadDigest = () => {
    DigestPage.preload();
  };

  const handleDigestClick = () => {
    navigate(pages.DIGEST);
  };

  return (
    <RootWrapper>
      <WrapperHeaderInfo justifyContent="flex-start">
        <Box flexGrow={1}>
          <SelectMenu
            data-cy="set_hotel_select"
            itemType="hotel"
            isAutocomplete
            hasBorder={false}
            value={active?.id}
            onChange={handleHotelChange}
            height={rem(40)}
            width={rem(248)}
            menuWidth={rem(460)}
            isLoading={isLoading || !active?.id}
            isListLoading={isListLoading}
            searchKeys={['id', 'title', 'address']}
            items={hotelsList}
            sxBox={{
              backgroundColor: themePalette.basic.gray['30'],
              padding: rem(0, 8),
            }}
            handleSearch={
              isNeedBackendSearch
                ? (text) => handleSearchHotel(text)
                : undefined
            }
          />
        </Box>

        <ContactsBoxStyled mr={rem(8)}>
          <Box display="flex">
            <Link
              data-cy="support_telegram_link"
              display="flex"
              alignItems="center"
              justifyContent="center"
              color="basic.green.90"
              href={feedback_url}
              target="_blank"
              rel="noopener,noreferrer"
              sx={{
                cursor: 'pointer',

                '&:hover': {
                  color: themePalette.basic.tertiary,
                },
              }}
            >
              {/*ViewBox изменен для решения задачи: https://crunchcode.atlassian.net/browse/LK-2919 */}
              <Telegram width={rem(24)} height={rem(24)} viewBox="0 0 25 25" />
            </Link>

            <Box display="flex" flexDirection="column" ml={rem(8)}>
              <Typography variant="caption2" color="basic.secondary">
                Обратиться в поддержку
              </Typography>
              <Box lineHeight={0}>
                <Typography variant="caption1" color="basic.primary">
                  +7 (800) 200 15 21
                </Typography>
                <Link
                  data-cy="support_mail_link"
                  href={`mailto:${otello_mail}`}
                  variant="caption1"
                  color="basic.primary"
                  underline="hover"
                  ml={rem(12)}
                  sx={{
                    cursor: 'pointer',

                    '&:hover': {
                      color: themePalette.basic.green['90'],
                    },
                  }}
                >
                  otello@2gis.ru
                </Link>
              </Box>
            </Box>
          </Box>
        </ContactsBoxStyled>

        <ContactsBoxStyled mr={rem(8)} sx={{ minWidth: rem(146) }}>
          <Typography
            variant="caption2"
            color="basic.secondary"
            height={rem(14)}
          >
            Менеджер региона
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            height={rem(18)}
            lineHeight={0}
          >
            <DataLoader
              data={managerContacts}
              isLoading={
                managerContactsIsLoading || isManagerContactsUninitialized
              }
              height={rem(12)}
            >
              {({ phone }) => (
                <Typography variant="caption1" color="basic.primary">
                  {getFormattedPhone(phone)}
                </Typography>
              )}
            </DataLoader>
          </Box>
        </ContactsBoxStyled>

        <MenuUser />

        <DigestBadge>
          <ButtonBase
            data-cy="digest_button"
            color="secondary"
            onClick={handleDigestClick}
            onMouseOver={handlePreloadDigest}
            startIcon={<Bell width={rem(20)} height={rem(20)} />}
            sx={{ width: rem(40), height: rem(40), padding: 0, ml: rem(8) }}
          />
        </DigestBadge>
      </WrapperHeaderInfo>

      <Divider
        light
        sx={{
          position: 'absolute',
          width: '100%',
          left: 0,
        }}
      />

      <NavigationDesktop />
    </RootWrapper>
  );
};
